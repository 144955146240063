:root {
	--fh: 'MD Nichrome', sans-serif;
	--fm: 'Cartograph CF', monospace;
	--vg: 8px; /* Vertical grid. */
}

html {
	font: 20px / calc(var(--vg) * 4) var(--fm);
}
h1,
h2,
h3,
h4 {
	font-family: var(--fh);
	font-weight: 800;
}
p,
ul {
	padding-bottom: calc(var(--vg) * 2);
}
h2 {
	padding-top: calc(var(--vg) * 3);
	padding-bottom: calc(var(--vg) * 2);
}
.fh {
	font-family: var(--fh);
}
.fm {
	font-family: var(--fm);
}

/******************************************************************************
*
* Cartograph CF font family.
*
*****************************************************************************/
@font-face {
	font-family: 'Cartograph CF';
	src: url(./fonts/cartograph-cf-thin.woff2) format('woff2'), url(./fonts/cartograph-cf-thin.woff) format('woff'),
		url(./fonts/cartograph-cf-thin.ttf) format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Cartograph CF';
	src: url(./fonts/cartograph-cf-thin-italic.woff2) format('woff2'),
		url(./fonts/cartograph-cf-thin-italic.woff) format('woff'),
		url(./fonts/cartograph-cf-thin-italic.ttf) format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Cartograph CF';
	src: url(./fonts/cartograph-cf-extra-light.woff2) format('woff2'),
		url(./fonts/cartograph-cf-extra-light.woff) format('woff'),
		url(./fonts/cartograph-cf-extra-light.ttf) format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Cartograph CF';
	src: url(./fonts/cartograph-cf-extra-light-italic.woff2) format('woff2'),
		url(./fonts/cartograph-cf-extra-light-italic.woff) format('woff'),
		url(./fonts/cartograph-cf-extra-light-italic.ttf) format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Cartograph CF';
	src: url(./fonts/cartograph-cf-light.woff2) format('woff2'), url(./fonts/cartograph-cf-light.woff) format('woff'),
		url(./fonts/cartograph-cf-light.ttf) format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Cartograph CF';
	src: url(./fonts/cartograph-cf-light-italic.woff2) format('woff2'),
		url(./fonts/cartograph-cf-light-italic.woff) format('woff'),
		url(./fonts/cartograph-cf-light-italic.ttf) format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Cartograph CF';
	src: url(./fonts/cartograph-cf-regular.woff2) format('woff2'),
		url(./fonts/cartograph-cf-regular.woff) format('woff'),
		url(./fonts/cartograph-cf-regular.ttf) format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Cartograph CF';
	src: url(./fonts/cartograph-cf-regular-italic.woff2) format('woff2'),
		url(./fonts/cartograph-cf-regular-italic.woff) format('woff'),
		url(./fonts/cartograph-cf-regular-italic.ttf) format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Cartograph CF';
	src: url(./fonts/cartograph-cf-demi-bold.woff2) format('woff2'),
		url(./fonts/cartograph-cf-demi-bold.woff) format('woff'),
		url(./fonts/cartograph-cf-demi-bold.ttf) format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Cartograph CF';
	src: url(./fonts/cartograph-cf-demi-bold-italic.woff2) format('woff2'),
		url(./fonts/cartograph-cf-demi-bold-italic.woff) format('woff'),
		url(./fonts/cartograph-cf-demi-bold-italic.ttf) format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Cartograph CF';
	src: url(./fonts/cartograph-cf-bold.woff2) format('woff2'), url(./fonts/cartograph-cf-bold.woff) format('woff'),
		url(./fonts/cartograph-cf-bold.ttf) format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Cartograph CF';
	src: url(./fonts/cartograph-cf-bold-italic.woff2) format('woff2'),
		url(./fonts/cartograph-cf-bold-italic.woff) format('woff'),
		url(./fonts/cartograph-cf-bold-italic.ttf) format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Cartograph CF';
	src: url(./fonts/cartograph-cf-extra-bold.woff2) format('woff2'),
		url(./fonts/cartograph-cf-extra-bold.woff) format('woff'),
		url(./fonts/cartograph-cf-extra-bold.ttf) format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Cartograph CF';
	src: url(./fonts/cartograph-cf-extra-bold-italic.woff2) format('woff2'),
		url(./fonts/cartograph-cf-extra-bold-italic.woff) format('woff'),
		url(./fonts/cartograph-cf-extra-bold-italic.ttf) format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Cartograph CF';
	src: url(./fonts/cartograph-cf-heavy.woff2) format('woff2'), url(./fonts/cartograph-cf-heavy.woff) format('woff'),
		url(./fonts/cartograph-cf-heavy.ttf) format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Cartograph CF';
	src: url(./fonts/cartograph-cf-heavy-italic.woff2) format('woff2'),
		url(./fonts/cartograph-cf-heavy-italic.woff) format('woff'),
		url(./fonts/cartograph-cf-heavy-italic.ttf) format('truetype');
	font-weight: 900;
	font-style: italic;
}

/******************************************************************************
*
* MD Nichrome font family.
*
*****************************************************************************/
@font-face {
	font-family: 'MD Nichrome';
	font-display: block;
	src: url(./fonts/MDNichromeTest-Infra.woff2) format('woff2'), url(./fonts/MDNichromeTest-Infra.woff) format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'MD Nichrome';
	font-display: block;
	src: url(./fonts/MDNichromeTest-InfraOblique.woff2) format('woff2'),
		url(./fonts/MDNichromeTest-InfraOblique.woff) format('woff');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'MD Nichrome';
	font-display: block;
	src: url(./fonts/MDNichromeTest-Thin.woff2) format('woff2'), url(./fonts/MDNichromeTest-Thin.woff) format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'MD Nichrome';
	font-display: block;
	src: url(./fonts/MDNichromeTest-ThinOblique.woff2) format('woff2'),
		url(./fonts/MDNichromeTest-ThinOblique.woff) format('woff');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'MD Nichrome';
	font-display: block;
	src: url(./fonts/MDNichromeTest-Light.woff2) format('woff2'), url(./fonts/MDNichromeTest-Light.woff) format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'MD Nichrome';
	font-display: block;
	src: url(./fonts/MDNichromeTest-LightOblique.woff2) format('woff2'),
		url(./fonts/MDNichromeTest-LightOblique.woff) format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'MD Nichrome';
	font-display: block;
	src: url(./fonts/MDNichromeTest-Regular.woff2) format('woff2'),
		url(./fonts/MDNichromeTest-Regular.woff) format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'MD Nichrome';
	font-display: block;
	src: url(./fonts/MDNichromeTest-RegularOblique.woff2) format('woff2'),
		url(./fonts/MDNichromeTest-RegularOblique.woff) format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'MD Nichrome';
	font-display: block;
	src: url(./fonts/MDNichromeTest-Dark.woff2) format('woff2'), url(./fonts/MDNichromeTest-Dark.woff) format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'MD Nichrome';
	font-display: block;
	src: url(./fonts/MDNichromeTest-DarkOblique.woff2) format('woff2'),
		url(./fonts/MDNichromeTest-DarkOblique.woff) format('woff');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'MD Nichrome';
	font-display: block;
	src: url(./fonts/MDNichromeTest-Bold.woff2) format('woff2'), url(./fonts/MDNichromeTest-Bold.woff) format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'MD Nichrome';
	font-display: block;
	src: url(./fonts/MDNichromeTest-BoldOblique.woff2) format('woff2'),
		url(./fonts/MDNichromeTest-BoldOblique.woff) format('woff');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'MD Nichrome';
	font-display: block;
	src: url(./fonts/MDNichromeTest-Black.woff2) format('woff2'), url(./fonts/MDNichromeTest-Black.woff) format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'MD Nichrome';
	font-display: block;
	src: url(./fonts/MDNichromeTest-BlackOblique.woff2) format('woff2'),
		url(./fonts/MDNichromeTest-BlackOblique.woff) format('woff');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'MD Nichrome';
	font-display: block;
	src: url(./fonts/MDNichromeTest-Ultra.woff2) format('woff2'), url(./fonts/MDNichromeTest-Ultra.woff) format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'MD Nichrome';
	font-display: block;
	src: url(./fonts/MDNichromeTest-UltraOblique.woff2) format('woff2'),
		url(./fonts/MDNichromeTest-UltraOblique.woff) format('woff');
	font-weight: 900;
	font-style: italic;
}
