.fit-text-parent {
	align-items: center;
	display: flex;
	justify-content: center;
}

.fit-text-child {
	font-family: var(--fh);
	margin: 0;
	text-align: center;
	white-space: nowrap;
	display: inline-block;
}
