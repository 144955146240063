h1 {
	padding-bottom: calc(var(--vg) * 2);
	color: var(--cy);
}

h1 > span {
	margin-bottom: calc(var(--vg) * 4);
}

iframe {
	border: 0;
	margin: calc(var(--vg) * 3) 0;
}

.rsvp-result {
	text-align: center;
	font-weight: 900;
	font-size: 44px;
	line-height: calc(var(--vg) * 6);
}

.rsvp-result::before {
	margin-bottom: calc(var(--vg) * 2);
}

.infinity {
	font-family: var(--fh);
	font-weight: 800;
	color: var(--cy);
	opacity: 0.3;
	transition: opacity 0.4s;
	margin: -10% 0;
	pointer-events: none;
}

.infinity.submitted {
	opacity: 1;
}

.infinity > :first-child {
	margin-bottom: -38%;
}
