label {
	display: block;
	text-transform: uppercase;
	line-height: calc(var(--vg) * 3);
	padding-top: calc(var(--vg) * 3);
	font-size: 15px;
	font-weight: 700;
}

fieldset label,
fieldset input {
	cursor: pointer;
}

fieldset label {
	display: inline-block;
	padding: var(--vg) 0 var(--vg) 1em;
}

input[type='text'],
input[type='email'],
textarea {
	border: 0;
	background: rgba(0, 0, 0, 0.06);
	padding: var(--vg);
	width: 100%;
	border-radius: 6px;
}

input[type='text'],
input[type='email'] {
	height: calc(var(--vg) * 6);
}

textarea {
	height: calc(var(--vg) * 18);
}

.rsvp-result::before,
.people > div:nth-last-child(n + 2)::after {
	content: '---------------------------- ';
	content: '---------------------------- ' / '';
	text-decoration: underline;
	text-decoration-style: wavy;
	font-size: 48px;
	height: calc(var(--vg) * 6);
	text-decoration-thickness: 2px;
	color: transparent;
	text-decoration-color: rgba(255, 255, 255, 0.9);
	overflow: hidden;
	width: 100%;
	display: block;
}

.rsvp-result::before {
	box-sizing: content-box;
	padding-bottom: calc(var(--vg) * 4);
	display: block;
}

.add-people-buttons {
	display: flex;
	justify-content: center;
}

.add-people-buttons button {
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--cy);
	color: var(--cb);
	font-size: 48px;
	height: calc(var(--vg) * 8);
	width: calc(var(--vg) * 8);
	border-radius: 999px;
	border: 0;
	margin: calc(var(--vg) * 4) 1em;
	font-weight: 900;
	padding: 3px 0 0;
}

.add-people-buttons button:hover {
	color: #fff;
}

button[type='submit'] {
	width: 100%;
	border: 0;
	margin-top: calc(var(--vg) * 4);
	outline-offset: 4px;
	border-radius: 6px;
	padding: 0;
	color: #666;
	background: rgba(0, 0, 0, 0.2);
}

button[type='submit']:focus:not(:focus-visible) {
	outline: none;
}

button[type='submit']:enabled span {
	color: #000;
	background: var(--cy);
}

button[type='submit'] span {
	will-change: transform;
	display: flex;
	height: calc(var(--vg) * 10);
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	transform: translateY(-4px);
	transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
	background: #eee;
}

button[type='submit']:disabled span {
	transform: none !important;
	cursor: not-allowed;
	transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

button[type='submit']:hover span {
	transform: translateY(-6px);
	transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

button[type='submit']:active span {
	transform: translateY(-2px);
	transition: transform 34ms;
}
