:root {
	--wiggle-amount: 3px;
}

.wiggle-char {
	animation: wiggle 2s ease-in-out infinite alternate;
}

@media (prefers-reduced-motion) {
	.wiggle-char {
		animation: none;
	}
}

@keyframes wiggle {
	from {
		transform: translate3d(0, calc(-1 * var(--wiggle-amount)), 0);
	}
	to {
		transform: translate3d(0, var(--wiggle-amount), 0);
	}
}
