html {
	scrollbar-color: var(--cy) Canvas;
}
:root {
	accent-color: var(--cy);
}
:focus-visible {
	outline-color: var(--cy);
}
::selection {
	background-color: var(--cy);
}
::marker {
	color: var(--cy);
}
:is(::-webkit-calendar-picker-indicator, ::-webkit-clear-button, ::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
	color: var(--cy);
}
*,
*::before,
*::after {
	margin: 0;
	box-sizing: border-box;
	transition: all 0.2s ease-in-out;
	transition-property: background-color, border-color, color, opacity;
}
::selection {
	background: magenta;
}
html,
body {
	overflow: auto;
}
ul,
ol {
	padding-left: 0.2em;
}
ul[class],
ol[class] {
	list-style: none;
	padding: 0;
}
li {
	list-style-position: inside;
}
input,
button,
textarea,
select,
pre,
code {
	color: inherit;
	font: inherit;
	letter-spacing: inherit;
	word-spacing: inherit;
}
button {
	cursor: pointer;
}
html {
	scroll-behavior: smooth;
}
@media (prefers-reduced-motion) {
	html {
		scroll-behavior: auto;
	}
}
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100vh;
	text-rendering: optimizeLegibility;
}
a {
	color: var(--cy);
	font-weight: 700;
}
a[target='_blank'] {
	cursor: alias;
}
sup {
	vertical-align: top;
	font-size: 0.6em;
}
