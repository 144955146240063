main {
	display: grid;
	grid-template-columns: 1fr min(65ch, 90%) 1fr;
	margin: auto;
	width: 100%;
	padding-bottom: 80px;
}

main > * {
	grid-column: 2;
}

main .full-bleed {
	width: 100%;
	grid-column: 1 / 4;
}
